.main-visual-sect{
  height: 41.5vw;
  min-height: 480px;
  max-height: 820px;
}
.main-visual{height: 100%;}
.visual-bg{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.visual01 .visual-bg{background-image: url(../img/main_visual_final01.jpg); background-color: #e0b9cc;}
.visual02 .visual-bg{background-image: url(../img/main_visual_final02.jpg); background-color: #b2b1b6;}
.visual03 .visual-bg{background-image: url(../img/main_visual_final03.jpg); background-color: #d8c0a0;}
.visual04 .visual-bg{background-image: url(../img/main_visual_final04.jpg); background-color: #b4c2d6;}
.visual05 .visual-bg{background-image: url(../img/main_visual_final05.jpg); background-color: #d4b0a0;}
.visual06 .visual-bg{background-image: url(../img/main_visual_final06.jpg); background-color: #b1c0b4;}
.visual07 .visual-bg{background-image: url(../img/main_visual_final07.jpg); background-color: #bbddde;}
.visual08 .visual-bg{background-image: url(../img/main_visual_final08.jpg); background-color: #aea69e;}
.visual09 .visual-bg{background-image: url(../img/main_visual_final09.jpg); background-color: #b2a9aa;}

.main-visual .visual01{background-color: #e0b9cc;}
.main-visual .visual02{background-color: #b2b1b6;}
.main-visual .visual03{background-color: #d8c0a0;}
.main-visual .visual04{background-color: #b4c2d6;}
.main-visual .visual05{background-color: #4c4c4c;}
.main-visual .visual06{background-color: #b1c0b4;}
.main-visual .visual07{background-color: #bbddde;}
.main-visual .visual08{background-color: #aea69e;}
.main-visual .visual09{background-color: #b2a9aa;}

.main-visual .visual06 .visual-bg-text .bk-text,.main-visual .visual06 .visual-bg-text .gray-text{position: absolute; bottom:4%; font-weight: normal;}
.main-visual .visual06 .visual-bg-text .bk-text{color: #000; opacity: 0.6; font-size: 15px; right:57.5%; letter-spacing: -0.02em;}
.main-visual .visual06 .visual-bg-text .gray-text{color: #fff; opacity: 0.3; font-size: 15px; right:2%; letter-spacing: -0.07em;}


.ani-fadeIn {
  opacity: 0;
}

.ani-scale-down {
  transform: scale(1.08);
  opacity: 0.2;
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
}

.swiper-slide-active .ani-fadeIn {
  -webkit-transition: all 1.6s;
  transition: all 1.6s;
  opacity: 1;
}

.swiper-slide-active .ani-scale-down {
  animation: scaleDown 1.6s ease-out forwards;
  -webkit-animation: scaleDown 1.6s ease-out forwards;
}

.swiper-slide-active .delay300 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.swiper-slide-active .delay500 {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.swiper-slide-active .delay600 {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.swiper-slide-active .delay800 {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.swiper-slide-active .delay1000 {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

@-webkit-keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.2;
  }

  100% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 1;
  }
}

.visual-tit{width:100%; height: 100%;}
.visual-tit .container1140{width:100%; height: 290px;}
.visual01 .visual-tit h4{text-transform: uppercase;}

.text-row {
	position: relative;
	overflow: hidden;
	display: block;
	white-space: nowrap;
}

.text-row > span {
  width: 100%;
  display: block;
  transform: translate3d(0,70%,0);
  -webkit-transform: translate3d(0,70%,0);
  -moz-transform: translate3d(0,70%,0);
  -ms-transform: translate3d(0,70%,0);
  -o-transform: translate3d(0,70%,0);
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-duration: 0.8s;
  animation-delay: .2s;
}
.text-row:nth-child(2) > span {animation-delay: .35s;}
.text-row:nth-child(3) > span {animation-delay: .5s;}
.text-row:nth-child(4) > span {animation-delay: .65s;}

.main-visual .swiper-slide-active .text-row > span{
  animation-name: titleAnimation;
}

@keyframes titleAnimation {
  0% {
    transform: translate3d(0,70%,0);
    -webkit-transform: translate3d(0,70%,0);
    -moz-transform: translate3d(0,70%,0);
    -ms-transform: translate3d(0,70%,0);
    -o-transform: translate3d(0,70%,0);
    opacity: 0;
}
  100% {
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    opacity: 1;
  }
}

.swiper-controls-wrap{position: absolute; bottom:30%; left:0; width: 100%; z-index: 10;}
.swiper-controls-wrap .swiper-pagination {
  position: relative;
  bottom: auto;
  left: auto;
  width: 100%;
  color: #fff;
  text-align: left;
  z-index: 9;
  text-shadow: 2px 2px 10px rgba(55,55,55,.4);
}

.main-ctrls{width: 30px; height: 30px; background: url(../img/main-ctrls.png)0 0/200% no-repeat; top:-6px; position: absolute; z-index: 10; cursor: pointer;}
.main-ctrls.prev{left:310px;}
.main-ctrls.next{background-position: 100% 0; left:360px;}

.main-progress-bar{position: absolute; left:90px; top:8px; width: 200px; height: 2px; background-color: rgba(255,255,255,.3); box-shadow: 2px 2px 10px rgba(55,55,55,.3);}
.progress-inner{width: 0%; height: 100%; display: block; background: #fff;}
.start .progress-inner{ animation: progressBar 5s linear forwards; -webkit-animation: progressBar 5s linear forwards; }

@keyframes progressBar {
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}

/* 진료과목 */
.fadeTxt{display: block;}
.fadeTxt span{display: block;}
.main-right-sect{padding:200px 0 200px 11%;}
.main-right__inner{max-width: 2000px; overflow: hidden;}

.main-right__tit{width: 20.66%; min-width: 230px; padding-right: 20px;}
.main-right__slide{width: 79.36%;}
.main-right__slide .swiper-slide img{width: 100%;}
.main-right__slide .swiper-slide figure .img-wrap{position: relative; overflow: hidden;}
.main-right__slide .swiper-slide figure .img-wrap::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c8d1e0;
  /* animation-timing-function: ease;
  animation-duration: 1.2s;
  animation-fill-mode: forwards; */
  /* transform: translate3d(0, -100%, 0);
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0); */
  transition: height 1.0s ease;
  -webkit-transition: height 1.0s ease;
  -moz-transition: height 1.0s ease;
  -ms-transition: height 1.0s ease;
  -o-transition: height 1.0s ease;
}




/* @keyframes imgReveal {
  0% {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
  }

  20% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
  }
} */

.main-right__slide .swiper-slide:nth-child(2) figure .img-wrap::after{ transition-delay:.15s; }
.main-right__slide .swiper-slide:nth-child(3) figure .img-wrap::after{ transition-delay:.3s;}
.main-right__slide .swiper-slide:nth-child(4) figure .img-wrap::after{ transition-delay:.45s;}
.main-right__slide .swiper-slide:nth-child(5) figure .img-wrap::after{ transition-delay:.6s;}
.main-right__slide .swiper-slide:nth-child(6) figure .img-wrap::after{ transition-delay:.75s;}
.waypoint.active .main-right__slide .swiper-slide figure .img-wrap::after{height: 0;}
/* .waypoint.active .main-right__slide .swiper-slide figure .img-wrap::after{ animation-name:imgReveal; } */

.main-right__slide .swiper-slide figure .img-wrap img{ transform:scale(1.1); -webkit-transform:scale(1.1); -moz-transform:scale(1.1); -ms-transform:scale(1.1); -o-transform:scale(1.1); transition:all 1.0s ease; -webkit-transition:all 1.0s ease; -moz-transition:all 1.0s ease; -ms-transition:all 1.0s ease; -o-transition:all 1.0s ease; }

.main-right__slide .swiper-slide:nth-child(2) figure .img-wrap img{ transition-delay:.35s; }
.main-right__slide .swiper-slide:nth-child(3) figure .img-wrap img{ transition-delay:.5s;}
.main-right__slide .swiper-slide:nth-child(4) figure .img-wrap img{ transition-delay:.65s;}
.main-right__slide .swiper-slide:nth-child(5) figure .img-wrap img{ transition-delay:.8s;}
.main-right__slide .swiper-slide:nth-child(6) figure .img-wrap img{ transition-delay:.95s;}

.waypoint.active .main-right__slide .swiper-slide figure .img-wrap img{opacity:1; transform:scale(1); -webkit-transform:scale(1); -moz-transform:scale(1); -ms-transform:scale(1); -o-transform:scale(1); }

.hov-img-wrap{position: absolute; top:0; left:0; width: 100%; overflow: hidden;}
.main-right__slide .swiper-slide figure .hov-img-wrap img{ opacity: 0; transform:scale(1); -webkit-transform:scale(1); -moz-transform:scale(1); -ms-transform:scale(1); -o-transform:scale(1); transition:1s ease; -webkit-transition:1s ease; -moz-transition:1s ease; -ms-transition:1s ease; -o-transition:1s ease; }
.main-right__slide .swiper-slide figure:hover .hov-img-wrap img{ opacity: 1; transform:scale(1.1); -webkit-transform:scale(1.1); -moz-transform:scale(1.1); -ms-transform:scale(1.1); -o-transform:scale(1.1); }


.headline{font-weight: 500;}
.headline.light,.headline .light{font-weight: 300;}
.fadeTxt.p-text{color: #888;}

.scrollbar-wrap{max-width: 1500px; overflow: hidden;}
.main-right-sect .swiper-scrollbar,
.prs-bna-sect .swiper-scrollbar{height: 4px;}

/* 비포 & 애프터 */
.spacing{margin-bottom: 230px;}
.prs-bna-sect{position: relative; overflow: hidden; padding:200px 0 220px}
.more-line-btn{display: inline-block; text-transform: uppercase; font-weight: 500; position: relative; margin-left: 1rem;}
.more-line-btn::after{content:''; position: absolute; bottom:0; left:0; width: 100%; height: 2px; background-color: #3e3e3e;}
.more-arr{width: 12px; height: 9px; display: inline-block; background: url(../img/right-arr-more.png)0 0/100% no-repeat;}
.c-white .more-arr{background: url(../img/right-arr-more-w.png)0 0/100% no-repeat;}
.c-white .more-line-btn::after{background-color: #fff;}
.pd_200{padding:200px 0;}
.bna-model{position: absolute; top:0; left:0; width: 100%; overflow: hidden;}
.bna-model-inner{position: relative; width:100%; padding:61.55% 0 0; overflow: hidden;}
.bna-model-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 1920px;
  height: 1186px;
  background: url(../img/prs-bna-sect-bg.jpg)0 0 /100% no-repeat;
}
.bna-slide-case-wrap{display: flex; }
.bna-slide-case-wrap > div{width: 49%; max-width: 200px; margin-right: 4px; position: relative;}

.prs-before-img::before{content:'BEFORE'; color:#fff; position: absolute; top:0; left:0; width: 100%; height: 100%; background: url(../img/note-before-layer-bg.png)0 0 no-repeat; font-weight: normal;text-align: center; padding:81% 0 0 ; box-sizing: border-box;}
.after-month{position: absolute; left:0; bottom:8px; width: 100%; display: block; text-align: center; color: #fff; font-size: 14px; font-weight: 300;}


.prs-bna-sect .swiper-slide img{max-width:100%;}
.prs-bna-sect .right-content{padding-left: 7.8125%; position: relative; z-index: 1;}


/* 리얼셀피 */
.big-tit{font-size: 5rem; text-transform:uppercase; }
.prs-real-selfie{ position: relative;}
.main-full-sect{
  height: 56.25vw;
  min-height: 480px;
  max-height: 1080px;
}
.main-full-sect .container,
.main-full-sect .flex_row{height: 100%;}

.video-real-selfie{position: absolute; top:0; left:0; width: 100%; height: 100%; }
.video-real-selfie-wrapper{padding:56.25% 0 0; position: relative;}
.video-real-selfie video{position: absolute; top:0; left:0; width: 100%; height: 100%;}
.video-real-selfie::after{content:''; position: absolute; top:0; left:0; width: 100%; height: 100%; background: rgba(0,0,0,.3);}

.more-btn-line{display: block; width: 200px; height: 50px; line-height: 1.5; padding:10px; border:2px solid #fff; font-weight: 500; margin-left: auto; margin-right: auto;}

/* 노트 이벤트 영역 */
.empty-area{padding:100% 0 0 ;}
.event-more-circle{position: absolute; top:31%; left:20%; width: 80px; height: 80px;}
.event-more-txt{position: absolute; top:57.5%; left:20%; font-size: 1.875rem; font-weight: 400;}


/* 유튜브 */
.prs-youtube-sect{position: relative;}
.prs-youtube-sect .more-line-btn{position: absolute; top:1rem; left:calc(50% + 8rem);}
.container1500{width: 100%; max-width: 1580px; margin-left: auto; margin-right: auto; padding:0 40px; position: relative; }
.yt-video-area{width: 54.9333%;}
.yt-thumb-area{padding-left:2.6667%; max-height: 600px;}
.yt-thumb-scroll{height: 100%; overflow-y: scroll;}
.yt-thumb-scroll li{cursor: pointer;}

.yt-thumb-area .flex_row .img-wrap{width: 27.95%; position: relative; max-width: 180px;}
.yt-thumb-area .flex_row .img-wrap::after{ content:''; position: absolute; left: 0; top:0; width: 100%; height: 100%; border-width: 6px; border-style:solid; border-color:rgba(0,0,0,0); box-sizing: border-box; transition:.4s ease; -webkit-transition:.4s ease; -moz-transition:.4s ease; -ms-transition:.4s ease; -o-transition:.4s ease; }
.yt-thumb-area .active .flex_row .img-wrap::after{ border-color:rgba(0,0,0,1);}
.yt-thumb-area .flex_row .text-wrap{padding-left:1.25rem; width: 72.05%;}
/* width */
.yt-thumb-scroll::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.yt-thumb-scroll::-webkit-scrollbar-track {
	background: #e5e5e5;
}

/* Handle */
.yt-thumb-scroll::-webkit-scrollbar-thumb {
	background: #343434;
}

/* Handle on hover */
.yt-thumb-scroll::-webkit-scrollbar-thumb:hover {
	background: #000;
}



.play-area{position: relative; width: 100%; padding-bottom: 56.25%; z-index: 1;}
.play-area iframe{position: absolute; width: 100%; height: 100%;}

/* 의료진 소개 */
.prs-doctors-sect{position: relative; height: 44.8vw; min-height: 530px; max-height: 860px; background-color: #484848;}
.prs-doctors-sect .parallax{position: relative; height: 100%;}
.prs-doctors-sect .bg{position: absolute; top:0; left:0; width: 100%; height: 100%; z-index: 1; background: url(../img/doctors-bg.jpg)50% 0/114% no-repeat;}
.prs-doctors-sect .note-logo{position: absolute; bottom:6.25rem; right:6.25rem; width: 6.25rem; height: 6.25rem; background: url(../img/note-logo-circle.png)0 0/100% no-repeat; z-index: 1;}

.prs-doctors-sect .doctors-copy-wrap{position: absolute; left:0; top:0; width: 100%; height: 100%; z-index: 2;}
.doctors-copy{max-width: 1240px; width: 100%; margin-left: auto; margin-right: auto; padding:26% 40px 0;}

#header .main-menu.depth1 li{list-style: none;}