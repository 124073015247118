.sub-headline {
  padding-top: 35px;
  padding-bottom: 10px;
  min-height: 56px;
  line-height: 2.3;
}


.sub-headline-tit a {
  font-weight: inherit;
  border-bottom: 1px solid #333333;
  text-decoration: none;
  color: inherit;
  font-weight: normal;
  font-size: 1rem;
}

.sub-top-section{background: #0a1a2c; display: flex; align-items: center;}
.sub-top-section.full-top-section{
  height: 38.625vw;
  min-height: 400px;
  max-height: 735px;
  background-position: top center;
  background-size: 1920px;
  background-repeat: no-repeat;
}
.sub-top-section.h300{
  height: 300px;
}
.sub-top-tit{letter-spacing: 1px;}
.sub-top-desc{opacity: 0.8; line-height: 1.5;}

.left-column-text .text-area{padding-right:2.5rem;}
.column_half{width:50%;}
.column_third{
  width:32%;
  margin-left: 2%;
}
.column_third:first-child{margin-left: 0;}
.column_quadter{
  margin-left: 2%;
  width: 23.5%;
}
.column_quadter:first-child{margin-left: 0;}
.column_fifth{
  margin-left: 2%;
  width: 18.2%;
}
.column_sixth{
  margin-left: 1%;
  margin-right: 1%;
  width: 14.6%;
}
.column_fifth:first-child{margin-left: 0;}
.column_15{width:15%;}
.column_20{width:20%;}
.column_25{width:25%;}
.column_30{width:30%;}
.column_33{width:33.3333%;}
.column_40{width:40%;}
.column_50{width:50%;}
.column_60{width:60%;}
.column_75{width:75%;}
.column_80{width:80%;}
.column_85{width:85%;}

.table-info *{line-height: 1.5; }
.table-info h4{padding-bottom:10px; }
.table-info p{padding-bottom:10px; }

.qna-item{overflow: hidden;}

.qna-item .question {
  position: relative;
  border-bottom: 1px solid #333;
  padding: 1rem 50px 1rem 20px;
  line-height: 1.65;
  cursor: pointer;
}

.qna-item .question:hover .toggle_icon,
.qna-item.active .question .toggle_icon {opacity: 1;}

.qna-item .answer {
  position: relative;
  border-bottom: 1px solid #333;
  padding: 1rem 50px 1rem 50px;
  line-height: 1.7;
}

.qna-item .toggle_icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  top: calc(50% - 1rem);
  right: 20px;
  border: 2px solid #333333;
  opacity: 0.4;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}

.toggle_icon .vert_icon,
.toggle_icon .hor_icon{ background-color: #333; position: absolute; top:50%; left:50%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }

.toggle_icon .vert_icon{ width:2px; height:11px; }
.toggle_icon .hor_icon{ width:11px; height:2px; }

.faq-item.active .toggle_icon .vert_icon,
.qna-item.active .toggle_icon .vert_icon {display: none;}


.faq-item{overflow: hidden;}
.faq-item .question {
  position: relative;
  border-bottom: 1px solid #333;
  padding: 1rem 50px 1rem 20px;
  line-height: 1.65;
  cursor: pointer;
}

.faq-item .question:hover .toggle_icon,
.faq-item.active .question .toggle_icon {opacity: 1;}

.faq-item .answer {
  position: relative;
  border-bottom: 1px solid #333;
  padding: 1rem 50px 1rem 50px;
  line-height: 1.7;
}

.faq-item .toggle_icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  top: calc(50% - 1rem);
  right: 20px;
  border: 2px solid #333333;
  opacity: 0.4;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}

.toggle_icon .vert_icon,
.toggle_icon .hor_icon{ background-color: #333; position: absolute; top:50%; left:50%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }

.toggle_icon .vert_icon{ width:2px; height:11px; }
.toggle_icon .hor_icon{ width:11px; height:2px; }


.bg_fa{ background-color: #fafafa;}
.bg_sky{ background-color: #eaf3ff;}
.bg_pink{ background-color: #ffebeb;}

.interior-slide .swiper-slide img{width: 100%;}

.interior-slide .swiper-button-next,
.interior-slide .swiper-button-prev{background: url(../sub/img/slidshow_arrow_white.png)0 0/200% no-repeat; width: 40px; height: 60px; }
.interior-slide .swiper-button-next::after,
.interior-slide .swiper-button-prev::after{content:'';}

.interior-slide .swiper-button-next{background-position: 100% 0;}
.interior-slide.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {margin: 0 2px;}
.interior-slide .swiper-pagination-bullet{width:13px; height:13px; border:2px solid #fff; background: transparent; opacity: .5;}
.interior-slide .swiper-pagination-bullet.swiper-pagination-bullet-active{background-color: #fff;}
.interior-slide .swiper-pagination,.interior-slide .swiper-button-next,.interior-slide .swiper-button-prev{ opacity:0; transition:opacity .4s; -webkit-transition:opacity .4s; -moz-transition:opacity .4s; -ms-transition:opacity .4s; -o-transition:opacity .4s; }
.interior-slide:hover .swiper-pagination,.interior-slide:hover .swiper-button-next,.interior-slide:hover .swiper-button-prev{opacity:1;}




/* slidshow_arrow_white.png */


/* sub2-1 안면윤곽 */
.sub-top-section.facial-contouring{background-image: url(../sub/img/facial_bone_contour_top_bg.jpg); background-color: #c6c6c6;}

.god_created_img{margin:0 1.2% 0 0; width:32.5%; max-width: 400px;}
.god_created_img:last-child{margin:0 0 0 0; }

.bg_bk{background-color: #000;}
.bg_sky2{background-color: #d4d5d6;}

.facial-cont-slide .swiper-slide .text-wrap{width:45.425%;}
.facial-cont-slide .swiper-slide .img-wrap{width:54.575%;}

.facial-cont-slide .swiper-slide .text-wrap .h2{margin-top: 100px;}

.consult-btn-wrap{width:170px; color: #fff; text-align: center;}
.consult-btn{ width:100%; background-color: #000;  border-radius:10px; -webkit-border-radius:10px ; -moz-border-radius:10px ; -ms-border-radius:10px ; -o-border-radius:10px ; display: block; padding:10px; font-size: 1.125rem;}


.facial-cont-slide .swiper-pagination{text-align: left; bottom:25%;}
.facial-cont-slide .swiper-pagination .swiper-pagination-bullet{width: 10px; height: 10px; border:1px solid #333; background-color: transparent;}

.facial-cont-slide2 .swiper-slide .img-wrap img{width: 100%;}
.hr_w hr{background-color: #fff;}

.player-16-9{position: relative; width:100%; padding:56.25% 0 0;}
.ytplayer{position: absolute; top:0; left:0; width: 100%; height: 100%;}


/* sub2-2 사각턱 성형 */
.sub-top-section.v-line{background-image: url(../sub/img/square_jaw_top_bg.jpg); background-color: #c2b89f;}
.mx320{max-width: 320px; margin-left: auto; margin-right: auto;}
.mx480{max-width: 480px;}
.mauto{margin-left: auto; margin-right: auto}
.mx820{max-width: 820px; margin-left: auto; margin-right: auto;}
.mx740{max-width: 740px; margin-left: auto; margin-right: auto;}

.mx820 .column_half{width:49%; margin-right: 2%;}
.mx820 .column_half:nth-child(2n){margin-right: 0%;}

.recovery_text_box{min-height:50px;}


/* sub2-4 */
.sub-top-section.cheekbone{background-image: url(../sub/img/cheekbone_reduction_main.jpg); background-color: #c2b89f;}
.sub-top-section.cheekbone-mid{background-image: url(../sub/img/cheekbone_reduction_main2.jpg); background-color: #fff;}

.tab-list{width: 400px; margin-left: auto; margin-right: auto;}
.tab-list > li{width: 200px; border:1px solid #707070; padding:8px; text-transform: uppercase;}
.tab-list > li.active{color: rgb(0, 122, 255);}
.tab-content{position: relative; width: 100%; height: 500px;}
.tab-item{position: absolute; top:0; left:0; width: 100%; background-color: #fff; z-index: 0;}
.tab-item:first-child{z-index: 1;}
.tab-item.active{z-index: 2;}

.faq-wrapper {padding-top: 3rem;}
.faq-wrapper .faq-list{
  width: 98%;
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  display: table;
  border-collapse: collapse;
}
.faq-wrapper .faq-list > li{cursor:pointer; width: 200px; border:1px solid #707070; padding:8px; text-transform: uppercase; display: table-cell;}
.faq-wrapper .faq-list > li.active{color: rgb(0, 122, 255);}

.faq-content-wrapper{height: auto; min-height: 700px; width: 700vw; display: flex;}
.faq-content-wrapper .faq-tab-item{background: #fafafa; width: 100vw;}



/* sub3-1 모티바 가슴성형 */
#content .table-info.bg_sky{padding:20px;}
.dotted_hr hr{background: none; border-top:1px dotted #333;}


/* sub4-1 */
.sub-top-section.deer_eyes{background-image: url(../sub/img/deer_eyes_main.png); background-color: #e1b6c8;}

.sub-section-01.bg_fa .column_half,
.sub-section-01.bg_fa .column_full{padding:3rem 0;}

.note-line-icon{width:150px; text-align: center; line-height: 1.6;}
.note-line-icon img{width:110px;}

.three-box-wrap{width:50%; max-width: 918px; min-width: 700px; margin-left:auto; margin-right:auto; position: relative; }

.three-box-list{width:43%;}
.three-box-listvs{width:14%; padding:12% 0 0; }

.pd_80{padding:5rem 0;}
.pd_100{padding:6.25rem 0;}

.sub4-1-key-bg{background-image: url(../sub/img/deer_eyes_imgbg02.jpg);}
.full-key-bg{background-position: 50% 0; background-size: cover; background-repeat: no-repeat; height: 790px;}

.doctor-profile-third{padding:24px;}
.doctor-profile-third figure{position: relative;}
.doctor-profile-caption{position: absolute; bottom:0; left:0; width:100%; padding: 15px; background-color: rgba(255, 255, 255, 0.3);}


.round-circle-item{ width:240px; height:230px; margin-left: 0;}
.round-circle-box{background: url(../sub/img/ulthera-trans-outline.png)0 0/100% no-repeat; position: relative; padding:95.5% 0 0; }
.round-circle-box > p{ position: absolute; top:50%; left:50%; width:100%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }


.effect_box_inner{width:121px; margin-left:auto; margin-right:auto; }

.method-icon {
  width: 65px;
  height: 65px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  background-image: url(../sub/img/method_ico_sprite.png);
  background-size: 400%;
  background-repeat: no-repeat;
}
.method-icon.icon1{background-position: 0 0;}
.method-icon.icon2{background-position: 33.3333% 0;}
.method-icon.icon3{background-position: 66.6666% 0;}
.method-icon.icon4{background-position: 100% 0;}
.method-icon.icon5{background-position: 0 100%;}
.method-icon.icon6{background-position: 33.3333% 100%;}
.method-icon.icon7{background-position: 66.6666% 100%;}
.method-icon.icon8{background-position: 100% 100%;}

.method-icon-tit{border-bottom: 1px solid #333; padding-bottom:10px;}

.sys-slide{width:88%; max-width:624px;}
.sys-slide .swiper-slide img{width: 100%;}

.slide-wrap .swiper-button-prev,
.slide-wrap .swiper-button-next{
  background: url(../sub/img/slidshow_arrow.png)0 0/200% no-repeat;
  width: 32px;
  height: 48px;
  top: 45%;
}

.slide-wrap .swiper-button-prev{
  background-position: 0 0;
  left:0;
}
.slide-wrap .swiper-button-next{
  background-position: 100% 0;
  right:0;
}

.slide-wrap .swiper-button-prev:after,.slide-wrap .swiper-button-next:after{
  display: none;
}

.bna-image{width:50%;}
.item-center{position: relative; margin-left:auto; margin-right:auto; }

.bna-hover-text{ position: absolute; top:0; left:0; width: 100%; height: 100%; background-color: rgba(0,0,0,.2); text-align: center; display: flex; align-items: center; justify-content: center; opacity: 0; transition:opacity .7s; -webkit-transition:opacity .7s; -moz-transition:opacity .7s; -ms-transition:opacity .7s; -o-transition:opacity .7s; }
.bna-image:hover .bna-hover-text{opacity:1;}
.bna-hover-text span {color: #fff;}

.sub-btm-section{display: flex; align-items: center; color: #fff; background-repeat: no-repeat; background-position: 50% 50%;}
.sub-btm-section.deer_eyes{background-image: url(../sub/img/deer_eye_img_footer.jpg); background-color: #e1b6c8;}

.full-btm-section{
  height: 38.625vw;
  min-height: 400px;
  max-height: 703px;
}





/* sub4-2 절개 쌍커풀 */
.sub-top-section.double-eyelid{background-image: url(../sub/img/double-eyelid_main_visual_0202_pc-scaled.jpg); background-color: #c6c6c6;}

.num-circle{ border-radius:50%; -webkit-border-radius:50%; -moz-border-radius:50%; -ms-border-radius:50%; -o-border-radius:50%; display: block; margin-left:auto; margin-right:auto;}
.num-circle.circle-white{width:3.125rem; height: 3.125rem; background: #fff; padding:.36rem;}

.image-container{width:100%; max-width: 1200px; margin-left:auto; margin-right:auto; position: relative; padding:0 30px;}

.text-box{width: 100%; padding:1.6em;}
.text-box.box-white{background-color: #fff;}
.special_amp{font-family: "Athiti", serif;}

.va_m{vertical-align: middle; display: inline-block;}

.case-item{width: 700px; margin-left:auto; margin-right:auto; position: relative;}
.case-item.nose-case{width: 410px; }
.case-item.cheekbone-case{width: 380px;}

.case-area{position: relative; padding:39% 0 0;}
.nose-case .case-area{padding:100% 0 0;}
.cheekbone-case .case-area{padding:77.63% 0 0; overflow: hidden;}

.case-before{position: absolute; top:0; left:0; width:100%; height:100%; }
.case-after{position: absolute; top:0; right:0; width:50%; height:100%; overflow: hidden;}
.case-after img{position: absolute; top:0; right:0; width:auto; height:100%; max-width: none;}

.div-line{position: absolute; top:0; left:50%; width:4px; height:273px; background: #fff; z-index: 1; cursor: ew-resize; margin-left:-2px}
.nose-case .div-line{ height:410px;}
.cheekbone-case .div-line{ height:295px;}
.div-line .cursor{
  /* transition: 0.25s; */
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff url(../sub/img/ico_bna_cursor.png)50% 50%/100% no-repeat;
  top: 90%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.case-area *,.case-area{pointer-events: none;-webkit-user-drag: none;overflow: hidden;}
.case-item *::selection{background: transparent;}
.bna-txt span{display: block; width: 50%;line-height: 2;}

/* input cursor style */
/* 
.case-item input[type=range] {
  position: absolute;
	width: 732px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	background: transparent;
	margin: 0;
  top:220px;
  left:-16px;
  z-index: 2;
  cursor: ew-resize;
}
.case-item input[type=range]::-ms-track { width: 732px; height:32px; cursor: pointer; background: transparent; border-color: transparent; color: transparent; }
.case-item input[type=range]:focus { outline: none; }

.case-item input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	background: #fff url(../sub/img/ico_bna_cursor.png)50% 50%/30px no-repeat;
	cursor: pointer;
	height: 32px;
	width: 32px;
	border-radius: 100%;
	-webkit-border-radius: 100%;
}

.case-item input[type=range]::-moz-range-thumb {
	-moz-appearance: none;
	background: #fff url(../sub/img/ico_bna_cursor.png)50% 50%/30px no-repeat;
	cursor: pointer;
	height: 32px;
	width: 32px;
	border-radius: 100%;
	-moz-border-radius: 100%;
}

.case-item input[type=range]::-ms-thumb {
	-ms-appearance: none;
	background: #fff url(../sub/img/ico_bna_cursor.png)50% 50%/30px no-repeat;
	cursor: pointer;
	height: 32px;
	width: 32px;
	border-radius: 100%;
	-ms-border-radius: 100%;
}

.case-item input[type=range]::-ms-fill-lower {
	background: transparent;
	border-radius: 24px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	-ms-border-radius: 24px;
	-o-border-radius: 24px;
}
.case-item input[type=range]::-ms-fill-upper {
	background: transparent;
	border-radius: 24px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	-ms-border-radius: 24px;
	-o-border-radius: 24px;
} */

.sub-btm-section.double-eyelid{background-image: url(../sub/img/double-eyelid_0202_footer.jpg); }

.qna-slide{
  width: 88%;
  max-width: 624px;
}

.qna-slide img{width: 100%;}


/* sub4-3 시크릿 눈매교정 */
.sub-top-section.secret-shape{background-image: url(../sub/img/secret-shape-of-eyes_main.jpg); background-color: #d7be9f;}

.column_third_alt{width:29.5%;}
.column_third_alt_arr{width:5.75%; }
.column_third_alt_arr > p{ padding:90% 8px 0;}

.secret-point-bg{background: url(../sub/img/eye-reshaping-methode-bg.jpg)50% 50%/cover no-repeat;}

.secret-point-bg .half-desc{margin-left: 53.5%;}
.secret-point-bg .half-desc .desc-item{width:239px;}
.secret-point-bg .half-desc .desc-item h2{height: 65px; background-color: #F5D9D9; color: #333333; text-align: center; padding:1.5em 1em;}
.secret-point-bg .half-desc .desc-item .inner{background-color: #fff; height: 280px; padding: 20px; line-height: 2;}

.plus-img{width:70px; padding:10px}

.sub-btm-section.secret-shape{background-image: url(../sub/img/secret-shape-of-eyes_footer.jpg);}

.round-tit{ border-radius:2rem; -webkit-border-radius:2rem; -moz-border-radius:2rem; -ms-border-radius:2rem; -o-border-radius:2rem; text-align: center; line-height: 2;}
.sm-round{width: 160px; margin-left: auto; margin-right: auto;}
.bg_blue{background-color: #007aff; color: #fff;}
.w300{width: 300px;}
.w1230{width: 1230px;}
.center-box{margin-left:auto; margin-right: auto;}

.bg_250{background-color:rgb(250, 250, 250);}
.pd_40{padding:40px 0;}
.text-963px{width: 963px; margin-left: auto; margin-right: auto;}

.secret-solution .swiper-pagination{position: relative;}
.secret-solution .swiper-pagination-bullet{ width: 210px; height:81px; padding: 31px 0 31px 0; border-radius: 30px; -webkit-border-radius: 30px; -moz-border-radius: 30px; -ms-border-radius: 30px; -o-border-radius: 30px; color: #333333; opacity:1; background-color: #fafafa; margin: 0 22px; outline:none}
.secret-solution .swiper-pagination-bullet:nth-child(1)::before{content:'절개 눈매교정';}
.secret-solution .swiper-pagination-bullet:nth-child(2)::before{content:'비절개 눈매교정';}
.secret-solution .swiper-pagination-bullet-active{background-color: #F5D9D9;}

.round-circle-box.blue-circle{background-image: url(../sub/img/ulthera-blue-box.png);}

.round-circle-box.blue-circle > div{ position: absolute; top:50%; left:50%; width:100%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }


/* sub4-7 눈 트임 – 듀얼트임 */
.sub-top-section.dual-eyelid{background-image: url(../sub/img/doul_eyelid_main.jpg); background-color: #e6974c;}

.dual_eyelid_content{
  width: 100%;
  max-width: 1170px;
  padding:0 40px;
  margin-left:auto;
  margin-right:auto;
  position: relative;
}

.dual_eyelid_content_bg{
  max-height: 530px;
  padding-top:48.63%;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  overflow: hidden;
  background: url(../sub/img/doul_eyelid_img02.jpg)0 50%/cover no-repeat;
}

.dual-eyelid-list-item{width: 21.1%;}
.dual-eyelid-list-arr{width: 5%; padding-top: 10%;}

.eyelid__round-tit {
  width: 330px;
  margin: 0 auto 30px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  border-radius: 17px;
  padding: 0 20px;
  color: #ffffff;
}
.normal.eyelid__round-tit {background-color: #909090;}
.dual.eyelid__round-tit {background-color: #007AFF;}

.dual-eyelid-point-bg{height: 793px; background:#f4e9e5 url(../sub/img/doul_eyelid_bgimg01.jpg)50% 0/1920px no-repeat;}
.dual-eyelid-point__inner{width: 100%; max-width: 580px; margin-left: auto; margin-right: auto; }

.round-white-box{ border-radius:10px; -webkit-border-radius:10px; -moz-border-radius:10px; -ms-border-radius:10px; -o-border-radius:10px; background: #fff; max-width: 469px; margin-left: auto; margin-right: auto; padding: 12px; line-height: 1.5;}

.round-org-box-wrap{max-width: 469px; margin-left: auto; margin-right: auto;}
.round-org-box{ border-radius:20px; -webkit-border-radius:20px; -moz-border-radius:20px; -ms-border-radius:20px; -o-border-radius:20px; background-color: #ea9d47;}
.round-org-box .p_r{ position: relative; padding:100% 0 0;}
.round-org-box .center-text{ position: absolute; top:50%; left:50%; width: 100%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }
.round-org-box-wrap .round-org-box{width: 44.776%;}
.round-org-box-wrap .plus-box{width: 10%; padding:16% 0 0;}

.sub-mid-section.dual-eyelid {
  padding-top: 0;
  background: url(../sub/img/doul_eyelid_bgimg02.jpg)50% 50%/cover no-repeat;
  height: 41.4vw;
  max-height: 795px;
  min-height: 480px;
}

.sub-mid-section.dual-eyelid .container{height: 100%;}
.sub-mid-section.dual-eyelid .title{height: 100%;}

.mx700{max-width: 700px;}

.sub-btm-section.dual-eyelid{background-image: url(../sub/img/doul_eyelid_footer.jpg); background-color: #e6974c;}


/* sub4-8 눈 밑 지방재배치 */
.sub-top-section.fat-under{background-image: url(../sub/img/fat-under-the-eyes-mainpc.jpg); background-color: #dfb4c5;}

.under-the-eyes-method-wrap{max-width: 1310px; margin-left: auto; margin-right: auto; width: 100%; padding: 0 40px;}

.under-the-eyes-method-item{width: 30%;}
.under-the-eyes-method-arr{width: 5%; padding:18% 0 0;}
.under-the-eyes-method-arr img{width: 50%;}

.under-the-eyes-method-wrap2{width: 75%; max-width: 806px; margin-left: auto; margin-right: auto; }
.under-the-eyes-method-wrap2 .under-the-eyes-method-item2{margin: 0 7px 0 8px; width: calc(50% - 15px);}
.under-the-eyes-blue-boder{
  width: 150px;
  margin: 0 auto 25px auto;
  line-height: 40px;
  border-radius: 20px;
  background-color: #007AFF;
  color: #ffffff;
  text-align: center;
}
.under-the-eyes-method-bgtitle{background-color: rgba(0, 0, 0, .1);    line-height: 40px; font-weight: 400;}
.mx572 {max-width: 572px;}
.case-item.fat-under .case-area{padding:51.4285% 0 0;}
.case-item.fat-under .div-line{height: 360px;}



/* sub4-9 아이핏 리프팅 */
.sub-top-section.eyefit{
  background-image: url(../sub/img/eyefit_main.jpg);
}

.sub-point-sect.eyefig-point-bg{background:#ebf3fe url(../sub/img/eyefit_bg01-scaled.jpg)50% 50%/1920px no-repeat;}

.eyefit-before-txt,
.eyefit-after-txt{position: absolute; top:50%; width: 150px; }
.eyefit-before-txt{left: -200px;}
.eyefit-after-txt{right: -200px;}

.over-hide{overflow: hidden;}

.sub-btm-section.eyefit{
  background-image: url(../sub/img/eye_fit_footer.jpg);
  background-color: #dbc4a2;
}


/* sub4-10 남자 눈성형 */
.sub-top-section.male-eyelid{
  background-image: url(../sub/img/male_eyelid_img_01.jpg);
  background-color: #cac9ce;
}
.male-eyelid-point-bg{background-color: #9eb9dd;}
.border-1px-round{ padding:1em; border-radius:10px ; -webkit-border-radius:10px ; -moz-border-radius:10px ; -ms-border-radius:10px ; -o-border-radius:10px ; border:1px solid #333; width: calc(100% - 15px); margin-left: 7px;}

.men-eyelid-case-item{width: 48%;}
.men-eyelid-case-arr{width: 4%; padding:8% 0 0;}
.men-eyelid-case-desc{
  background-color: #CCDCF1;
  color: #333333;
  padding: 15px;
  text-align: center;
}


.sub-mid-section.male-eyelid {
  padding-top: 0;
  background:#8e8d8b url(../sub/img/male_eyelid_img_08.jpg)50% 50%/1920px no-repeat;
  height: 39.0625vw;
  max-height: 750px;
  min-height: 480px;
}

.sub-mid-section.male-eyelid .container{height: 100%;}
.sub-mid-section.male-eyelid .title{height: 100%;}

.male-eyelid .doctor-profile-caption{background-color: transparent; padding:15px 25px}
.male-eyelid .doctor-profile-caption *{color: #fff;}

.sub-btm-section.male-eyelid{
  background-image: url(../sub/img/male_eyelid_img_09.jpg);
  background-color: #000;
}

.male-eyelid-point-desc{width: 100%; margin-left: auto; margin-right: auto; max-width: 650px;}
.male-eyelid-point-desc .left-desc,
.male-eyelid-point-desc .right-desc{width: 45%;}
.bluetop-twobox-top{padding: 15px; background-color: #057AFF;}
.bluetop-twobox-bottom{height: 260px; background-color: #ffffff; color: #333; padding: 25px; line-height: 2.188;}
.male-eyelid-point-desc .mid-plus-icon{
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  color: #057AFF;
  text-align: center;
  font-weight: bold;
}



/* sub4-11 눈 재수술 */

.sub-top-section.revision{
  background-image: url(../sub/img/pc_eyelid-revision_renew_main.png);
  background-color: #565660;
}
.new-eye-revision{width: 600px; margin-left: auto; margin-right: auto;}
.new-eye-revision-title{width: 210px;}
.new-eye-revision-listwrap{width: 390px; }
.new-eye-revision-listwrap li{margin-left: 1em; padding: 3px 0;}

.revision-slide-wrap{
  width: 100%;
  max-width: 888px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.revision-slide-wrap .revision-slide-inner{min-height: 500px;}

.revision-slide-inner{width: 100%; height: 100%; max-width: 530px; margin-left: auto; margin-right: auto;}
.revision-note-desc{padding: 25px;}
.bg-white{background-color: #fff;}

.revision-note-tit{width: 80px;}
.revision-note-ul{width: calc(100% - 80px); line-height: 1.4; min-height: 100px;}
.revision-note-ul.disc-ul{padding-left: 1rem;}
.revision-note-ul.disc-ul li{position: relative; margin-bottom: 5px;}
.revision-note-ul.disc-ul li::before {
  content: '';
  position: absolute;
  top: .6rem;
  left: -0.5rem;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #333;
}

.revision-next-btn,.revision-prev-btn{position: absolute; bottom:0; left:calc(50% + 270px); cursor: pointer;}
.revision-next-btn span{display: inline-block; vertical-align: middle; text-decoration: underline;}
.play-icon{width: 46px; height: 46px; display: inline-block; vertical-align: middle; background: url(../sub/img/eyelid-revision_renew_btn.png)0 0/100% no-repeat;}




/* sub5-1 내추럴 UMBG 코성형 */
.sub-top-section.natural-umbg{
  background-image: url(../sub/img/natural_umbg_rhinoplasty_main.jpg);
}

.video-wrapper{
  width:100%;
	max-width: 1480px;
  height: 832.5px;
	margin: 0 auto;
}

.sub-mid-section{
  padding-top: 380px;
}
.sub-mid-section.natural-umbg{
  background: url(../sub/img/natural_rhinoplasty2.jpg)50% 50%/cover no-repeat;
}

.jc_center .round-circle-item{margin-left:60px; margin-right: 60px;}

.recovery-wrap{width: 570px; margin-left:auto; margin-right:auto;}
.recovery-item{width: 190px; text-align: center;}
.ovl-tit{ border-radius:100%; -webkit-border-radius:100%; -moz-border-radius:100%; -ms-border-radius:100%; -o-border-radius:100%; border:1px solid #333; width:63px; padding:.75em; margin-left:auto; margin-right:auto;}

.hover-img{position: relative; padding:100% 0 0; overflow: hidden;}
.hover-img-container{ position: absolute; top:0; left:0; width: 100%; height: 100%; transition:.6s ease; -webkit-transition:.6s ease; -moz-transition:.6s ease; -ms-transition:.6s ease; -o-transition:.6s ease; }
.hover-img:hover .hover-img-container{ transform:scale(1.05); -webkit-transform:scale(1.05); -moz-transform:scale(1.05); -ms-transform:scale(1.05); -o-transform:scale(1.05); }

.nose-doctor{width: 87%; margin-left: auto; margin-right: auto;}


/* sub9-2 피부과 써마지 FLX */
.sub-top-section.thermage{
  background-image: url(../sub/img/thermage-flx-main.jpg);
  background-color: #b3adaf;
}

.table-wrap.thermage{width: 812px; margin-left: auto; margin-right: auto; background: #fafafa; padding: 30px 69px;}
.thermage-table{width: 100%;}
.thermage-table .category span {
  display: inline-block;
  border-radius: 20px;
  background-color: #EAF3FF;
  width: 135px;
  padding: 10px 0;
  font-weight: 400;
}

.table-wrap .thermage-table th, .thermage-table td {padding: 5px;}
.table-wrap .thermage-table th{width: 33.333%;}
.table-wrap .thermage-table tr:nth-child(2) > td {padding-top: 20px; border-top:1px solid #333;}

.thermage-point-bg{background:#bbb9ba url(../sub/img/thermage-pros-dt1-1.gif)50% 100% no-repeat;
  height: 768px;
}

.bg_dk_gray{background-color:#b5babf}

.genuine-thermage{padding-top: 100px;}

.sub-btm-section.thermage{background-image: url(../sub/img/bl_cookie_image.jpg);}

.thermage-solution{padding-top: 100px;}
.thermage-solution-bg{
  height: 735px;
  background:#53535d url(../sub/img/thermage_flx_area_pc01-scaled.jpg)50% 50%/1920px no-repeat;
}

/* sub9-3 울쎄라 플러스 */

.sub-top-section.ulthera{
  background-image: url(../sub/img/ulthera_new_main_pc.jpg);
  background-color: #000;
}

.pt_80{padding-top:80px}
.pt_100{padding-top:100px}

.sub-btm-section.ulthera{
  background-image: url(../sub/img/bm_cookie_image.jpg);
  background-color: #565660;
}

/* sub9-4 한관종 치료 */
.sub-top-section.syringoma{
  background-image: url(../sub/img/syringoma-main.jpg);
  background-color: #e2b7c8;
}

.syringoma-why-sect{
  background-image: url(../sub/img/syringoma_why_img01.jpg);
  background-color: #e2b7c8;
  background-position: 75% 50%;
  height: 703px;
}
.syringoma_why_text{ background-color: #fff; border-radius:10px; -webkit-border-radius:10px; -moz-border-radius:10px; -ms-border-radius:10px; -o-border-radius:10px; line-height: 1.5; padding:12px; margin-bottom: 20px;}

.syringoma_why_text.last_text{background-color: #ffddba;}

.syringoma_1on1_sect{background: url(../sub/img/syringoma_mtmgray_img01.jpg)50% 50% no-repeat;}

.sub-btm-section.syringoma{background-image: url(../sub/img/han_Treatment_main.png);background-color: #e2b7c8;}


/* sub9-5 홍반유도 */
.sub-top-section.v-solution{
  background-image: url(../sub/img/v-solution-pc-main.jpg);
  background-color: #dbbea0;
}

.v-solution-list li{padding:25px;}

.bg_org{
  background-color: #ffddba;
}

.vsolution_ico_wboxalign{ width: 227px; text-align: center;}
.vsolution_ico_wbox{width: 180px; background: #fff; border-radius: 30px; -webkit-border-radius: 30px; -moz-border-radius: 30px; -ms-border-radius: 30px; -o-border-radius: 30px; padding: 10px; margin-left: auto; margin-right: auto;}

.vsolution_icon{background: url(../sub/img/vsolution_icon_sprite.jpg)0 0/400% no-repeat; width:136px; height: 136px; display: inline-block;}
.vsolution_ico_plus{width: 40px; background: url(../sub/img/v-solution-ico-plus.png)0 90px/100% no-repeat;}

.vsolution_icon.icon1{background-position: 0 0;}
.vsolution_icon.icon2{background-position: 33.3333% 0;}
.vsolution_icon.icon3{background-position: 66.6666% 0;}
.vsolution_icon.icon4{background-position: 100% 0;}

.rel{position: relative;}
.down-arr{position: absolute; bottom:-55px; left:calc(50% - 45px);}

.vsolution_ico_bottom{width: 480px; height: 80px; background-color: #FF966F; margin-left: auto; margin-right: auto; color: #fff; text-align: center; padding:1.8rem 1rem; line-height: 1.5; border-radius: 20px;}

.sub-btm-section.v-solution{background-image: url(../sub/img/vsolution_footer.jpg); background-color: #dbbea0;}
/* vsolution_special4_img-1.png */

.vsolution_tit{ background-color: #FF966F; color: #fff; border-radius:20px; -webkit-border-radius:20px; -moz-border-radius:20px; -ms-border-radius:20px; -o-border-radius:20px; padding:10px; text-align: center; width: 380px; margin-left: auto; margin-right: auto; }


.vsolution-step:nth-child(odd){width:306px;}
.vsolution-step:nth-child(even){width:40px; background: url(../sub/img/vsolution_special1_arrow.png)50% 50% no-repeat;}

.vsolution-step-tit{ background: #e5e5e5; padding: 10px;}

.vsolution_special2_slide_txt{display: flex;}
.vsolution_special2_slide_txt h5{width:64px; height: 64px; background-color: #333; color: #fff; margin-top: 3px; line-height: 64px;}
.vsolution_special2_slide_txt p{padding-left: 1rem;}

.vsolution-point02 .slide-wrap .swiper-button-prev {left: -40px;}
.vsolution-point02 .slide-wrap .swiper-button-next {right: -40px;}

.vsolution-point04-list{width: 100%; max-width:600px;}
.circle-org{ width:100%; padding:100% 0 0; position: relative; background-color: #ffddba; border-radius:100%; -webkit-border-radius:100%; -moz-border-radius:100%; -ms-border-radius:100%; -o-border-radius:100%; }
.circle-org .center-text{ width:100%; position: absolute; top:50%; left:50%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); }


/* sub9-6 스킨부스터 */
.sub-top-section.skin-booster{background-image: url(../sub/img/skin_booster_main_pc.jpg); background-color: #c2b7a5;}

.sub-btm-section.skin-booster{background-image: url(../sub/img/skin_booster_footer.jpg); background-color: #c2b7a5;}

/* skin_booster_doctor.jpg */


.skin_booster_doctor {
  background-image: url(../sub/img/skin_booster_doctor.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1920px;
  height: 600px;
}

.booster-program .swiper-pagination{position: relative; background-color: #EAF3FF; padding: 30px 0;}
.booster-program .swiper-pagination-bullet{ width: 210px; height:81px; padding: 31px 0 31px 0; border-radius: 30px; -webkit-border-radius: 30px; -moz-border-radius: 30px; -ms-border-radius: 30px; -o-border-radius: 30px; color: #333333; opacity:1; background-color: #fafafa; margin: 0 22px; outline:none}
.booster-program .swiper-pagination-bullet:nth-child(1)::before{content:'물광 주사';}
.booster-program .swiper-pagination-bullet:nth-child(2)::before{content:'PRP 주사';}
.booster-program .swiper-pagination-bullet:nth-child(3)::before{content:'리쥬란 힐러';}
.booster-program .swiper-pagination-bullet:nth-child(4)::before{content:'아기 주사';}
.booster-program .swiper-pagination-bullet:nth-child(5)::before{content:'PDRN 주사';}
.booster-program .swiper-pagination-bullet-active{background-color: rgba(0, 122, 255,.33); color: #fff;}


.ulthera-small-box{width: 156px; height: 149px; background: url(../sub/img/ulthera-trans-outline.png)0 0/100% no-repeat; margin-right: 1rem; position: relative;}

.ulthera-small-box .center-text{ position: absolute; top:50%; left:50%; width:100%; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); text-align: center;}

/* sub9-7 닥터 레시피 주사 */
.sub-top-section.dr-recipe{background-image: url(../sub/img/dr-recipe-top-visual.jpg); background-color: #b1b6b5;}
.line-up-block{padding: 8.8%;}
.md-round{width: 320px; margin-left: auto; margin-right: auto;}

.ordered-tit span{display: inline-block;}
.circle-num{ width: 2.5rem; height: 2.5rem; border:1px solid #707070; border-radius:50%; -webkit-border-radius:50%; -moz-border-radius:50%; -ms-border-radius:50%; -o-border-radius:50%; line-height: 2.5rem; margin-right: 6px;}
.ordered-tit-txt{line-height: 2.5rem;}

.dr-recipe-method-list{width: 22.95%;}
.dr-recipe-method-list-arr{width: 2.73%; text-align: center; padding-top:11%;}

.dr-recipe-ingredient{max-width: 947px; margin-left: auto; margin-right: auto; position: relative;}
.dr-recipe-ingredient-inner{ width: 76%; margin-left: auto; margin-right: auto; border-radius:100%; -webkit-border-radius:100%; -moz-border-radius:100%; -ms-border-radius:100%; -o-border-radius:100%; padding-top:76%; border:1px solid #707070; position: absolute; top:50%; left:50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); }

.dr-recipe-ingredient-item{ position: absolute; width: 8.75rem; height: 8.75rem; background-color: #EAF3FF; border:1px solid #707070; border-radius:100%; -webkit-border-radius:100%; -moz-border-radius:100%; -ms-border-radius:100%; -o-border-radius:100%; }
.dr-recipe-ingredient-item span{ position: absolute; top:50%; left:50%; transform:translate3d(-50%,-50%,0) ; -webkit-transform:translate3d(-50%,-50%,0) ; -moz-transform:translate3d(-50%,-50%,0) ; -ms-transform:translate3d(-50%,-50%,0) ; -o-transform:translate3d(-50%,-50%,0) ; }


.dr-recipe-ingredient-item.item01{left:-5%; top:21%}
.dr-recipe-ingredient-item.item02{left:-5%; top:60%}
.dr-recipe-ingredient-item.item03{left:21%; top:87%}
.dr-recipe-ingredient-item.item04{left:60%; top:87%}
.dr-recipe-ingredient-item.item05{left:86%; top:60%}
.dr-recipe-ingredient-item.item06{left:86%; top:21%}
.dr-recipe-ingredient-item.item07{left:60%; top:-5%}
.dr-recipe-ingredient-item.item08{left:21%; top:-5%}

.dr-recipe-doctor-item{width: 32.786%; margin-right: 0.821%;}
.dr-recipe-doctor-item:last-child{ margin-right: 0%;}
.dr-recipe-doctor-item figure{position: relative;}
.dr-recipe-doctor-item figcaption{position: absolute; bottom:0; left:0; width: 100%; padding:6%; color: #fff; z-index: 1; font-weight: 500; line-height: 1.5;}

/* 리얼셀피 게시판 스타일 */
.bbs-real-selfie-box{padding:40px 40px}
.bbs-real-selfie-box:nth-child(odd){background-color: #fafafa;}
.bbs-real-selfie-box:nth-child(even){background-color: #fff;}
.bbs-real-selfie-box .column_75{padding:0 0 0 6%;}

.sub-top-section.covid19 {
  background-image: url(../sub/img/covid-main-banner-img-pc.jpg);
  background-color: #9e9791;
}
.sub-top-section.covid19 .sub-top-tit-wrap{width: 50%; margin-left: auto;}

.top-bnr-img{
  height: 26vw;
  min-height: 367px;
  max-height: 500px;
  padding:5% 0 0;
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.sub-covid19-sect.sect-01 .top-bnr-img{
  background-image: url(../sub/img/covid-365-banner-img-pc.jpg);
}
.sub-covid19-sect.sect-02 .top-bnr-img{
  background-image: url(../sub/img/covid-regular-banner-img-pc.jpg);
}
.sub-covid19-sect.sect-03 .top-bnr-img{
  background-image: url(../sub/img/covid-system-banner-img-pc.jpg);
}
.sub-covid19-sect.sect-04 .top-bnr-img{
  background-image: url(../sub/img/covid-premium-banner-img-pc.jpg);
}
.covid19-contents{max-width: 1310px; margin-left: auto; margin-right: auto; width: 100%; padding:0 40px;}

.covid19-item-wrap{display: flex; margin: -84px 0 80px;}
.covid19-item-wrap.right-items{justify-content: flex-end;}
.covid19-circle-item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 168px;
  background: #fff;
  border: 1px solid #BCBCBC;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.covid19-item-wrap .covid19-circle-item + .covid19-circle-item {
  margin-left: 40px;
}
.mx640{max-width: 640px;}

.covid19-bottom-sect{
  height: 900px;
  background: url(../sub/img/covid_bottom_banner-img.jpg)50% 50%/cover no-repeat;
  padding:36rem 0 0;
}

/* 핀리스 */
.sub-top-section.pin-less {
  background-image: url(../sub/img/pinless-main-visual-pc.jpg);
  background-color: #cddde0;
}

.pinless-sect01{
  height: 684px;
  padding:140px 0 0;
  background: #cddde0 url(../sub/img/pinless-sect01-bg.jpg)50% 0 no-repeat;
}
.pin-less-sect01-box{width: 50%; margin-left: auto; background-color: #fff; padding:5%;}

.pinless-hlight{background-color: rgba(202, 217, 222,.5); display: inline-block;}

.pinless-skull-list,.pinless-steps-list{width: 307px;  position: relative; margin-right: auto; margin-left: auto;}
.pinless-skull-list{height: 380px;}
.pinless-steps-list{height: 80px;}
.pinless-skull-list li,.pinless-steps-list li{position: absolute; top:0; left:0; width: 100%; height: 100%;}
.pinless-slider-wrap{max-width: 600px; margin-left: auto; margin-right: auto; position: relative;}
.pinless-slider-wrap::before{content:''; position: absolute; top:50%; left:0; width: 100%; height: 2px; background-color: #111; z-index: 0;}
.pinless-slider-wrap .placeholder-circle{ width: 34px; height: 34px; border-radius:50%; -webkit-border-radius:50%; -moz-border-radius:50%; -ms-border-radius:50%; -o-border-radius:50%; position: absolute; top:0; border:2px solid #b3d3da; background-color: #fff; z-index: 0;}

.pinless-slider-wrap .placeholder-circle.num0{left:0;}
.pinless-slider-wrap .placeholder-circle.num1{left:calc(50% - 17px);}
.pinless-slider-wrap .placeholder-circle.num2{right:0;}

.pinless-slider-wrap input[type=range] {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 7px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.pinless-slider-wrap input[type=range]:focus {
  outline: none;
}
.pinless-slider-wrap input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  width: 100%;
  height: 20px;
  cursor: pointer;
}
.pinless-slider-wrap input[type=range]::-webkit-slider-thumb {
  margin-top: -7px;
  width: 34px;
  height: 34px;
  background: #b3d3da;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 34px;
  cursor: pointer;
  -webkit-appearance: none;
}
.pinless-slider-wrap input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
.pinless-slider-wrap input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  width: 100%;
  height: 20px;
  cursor: pointer;
}
.pinless-slider-wrap input[type=range]::-moz-range-thumb {
  width: 34px;
  height: 34px;
  background: #b3d3da;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 34px;
  cursor: pointer;
}
.pinless-slider-wrap input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8px 0;
  color: transparent;
  width: 100%;
  height: 20px;
  cursor: pointer;
}
.pinless-slider-wrap input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0;
}
.pinless-slider-wrap input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0;
}
.pinless-slider-wrap input[type=range]::-ms-thumb {
  width: 34px;
  height: 34px;
  background: #b3d3da;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 34px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.pinless-slider-wrap input[type=range]:focus::-ms-fill-lower {
  background: transparent;
}
.pinless-slider-wrap input[type=range]:focus::-ms-fill-upper {
  background: transparent;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  .pinless-slider-wrap input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}


.pinless-round-title{ font-weight: 500; width: 100%; max-width: 520px; margin-left: auto; margin-right: auto; text-align: center; border:1px solid #cacaca; border-radius:40px; -webkit-border-radius:40px; -moz-border-radius:40px; -ms-border-radius:40px; -o-border-radius:40px; height: 56px; padding:15px; font-size: 1.125rem; letter-spacing: -0.03em;}

.pinless-ct-sect-notice{max-width: 840px; margin-left: auto; margin-right: auto; padding:0 20px;}
.notice-title{letter-spacing: 0.2em; font-weight: 500; font-size: 1.375rem;display: inline-block; background-color: rgba(200, 217, 222, 0.5); padding:0 10px;}

.pinless-ct-slider{max-width: 869px;}

.pinless-ct-slider .revision-next-btn, .pinless-ct-slider .revision-prev-btn{left:auto; bottom:auto; top:50%; z-index: 1;}
.pinless-ct-slider .revision-next-btn{right:0;}
.pinless-ct-slider .revision-prev-btn{ left:0; transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg); }

.pinless-ct-slider .revision-next-btn.hidden-btn, .pinless-ct-slider .revision-prev-btn.hidden-btn{opacity:0; visibility: hidden;}

.case-item.pin-less-case{width: 770px;}
.case-item.pin-less-case .case-area{padding:61.17% 0 0;}
.case-item.pin-less-case .div-line{height: 471px;}


/* sub2-맨즈윤곽 */
.sub-top-section.mans-vline{background-image: url(../sub/img/mans-vline-top-visual-pc.jpg); background-color: #cbcbcb;}

.sub-mid-section.mans-vline{
  height: 39.0625vw;
  max-height: 750px;
  min-height: 480px;
  background-image: url(../sub/img/mans-vline-mid-visual-pc.jpg); background-color: #ebebeb; padding-top: 310px;
}

.mans-vline-case1{max-width: 1230px; width: 100%; margin-left: auto; margin-right: auto;}
.mans-vline-case1 .case1,.mans-vline-case1 .case2{width: 40%;}
.mans-vline-case1 .vs-circle{ width: 10rem; height: 10rem; background: #B6DBF5; color: #fff; display: block; border-radius:10rem; -webkit-border-radius:10rem; -moz-border-radius:10rem; -ms-border-radius:10rem; -o-border-radius:10rem; line-height: 10rem; text-align: center; font-size: 3.75rem;}

.mans-vline-case1-tit{ background: #007AFF; color: #fff; width: 138px; border-radius:3em; -webkit-border-radius:3em; -moz-border-radius:3em; -ms-border-radius:3em; -o-border-radius:3em; margin-left: auto; margin-right: auto; text-align: center; font-weight: 500; padding:.3em; line-height: 1.4;}

.cont-divid{max-width: 1230px; height: 1px; background-color: #707070; margin-left: auto; margin-right: auto;}

.mans-vline-case2-wrap{width:100%; max-width: 860px; margin-left: auto; margin-right: auto; }

.mans-vline-before,.mans-vline-after{width:44.186%; text-align: center;}
.mans-vline-before figcaption,.mans-vline-after figcaption{padding:1em; background-color: #CCDCF1;}
.mans-vline-arrow{width: 11.628%; text-align: center; padding:0 10px;}

.mans-vline-bna-wrap{width: 100%; max-width: 1230px; margin-left: auto; margin-right: auto;}
.mans-vline-bna-item{width:46.7%;}
.mans-vline-bna-item:first-child{margin-right: 20px;}
.mans-vline-bna-item figure:first-child{margin-right: 10px;}


/* sub5-맨즈 코 */
.sub-top-section.mans-nose{background-image: url(../sub/img/mans-nose-top-visual-pc.jpg); background-color: #941c1e;}

.mans-nose-detail-wrap{max-width: 920px; margin-left: auto; margin-right: auto; }

.mans-nose-detail-desc{padding-top:26%; text-align: center;}
.mans-nose-detail-desc .mans-vline-case1-tit{width: auto; display: inline-block; padding: .3em .6em;}
.udl{text-decoration: underline;}

.mans-nose-tab .swiper-pagination{
  position: relative;
  background-color: #EAF3FF;
  padding: 30px 0;
}
.mans-nose-tab .swiper-pagination-bullet {
  width: 210px;
  height: 81px;
  padding: 31px 0 31px 0;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  color: #333333;
  opacity: 1;
  background-color: #fafafa;
  margin: 0 22px;
  outline: none;
}
.mans-nose-tab .swiper-pagination-bullet-active {
  background-color: rgba(0, 122, 255,1);
  color: #fff;
}

.mans-nose-tab .swiper-pagination-bullet:nth-child(1)::before {
  content: '자가 조직';
}
.mans-nose-tab .swiper-pagination-bullet:nth-child(2)::before {
  content: '인공 재료';
}


.mans-nose-material01-wrap{max-width: 1100px;}
.mans-nose-material01-wrap .column_33{padding:0 10px;}
.m_auto{margin-left: auto; margin-right: auto;}


.mans-nose-bna-item{width: 46%; max-width: 560px;}

.mans-to-umbg-bnr{background: #9b1d20;}
.mans-to-umbg-bnr a{display: block; width: 100%; height: 100%;}



/* 낮은 코 리뉴얼페이지 */
.sub-top-section.flat-nose{background-image: url(../sub/img/flat-nose-top-visual-pc.jpg); background-color: #dadee1;}
.mans-vline-case1-tit.bg_bk{background-color: #242424;}

.flat-to-umbg-bnr{background: linear-gradient(to right,#c2baa5,#c0b59f);}
.flat-to-umbg-bnr a{display: block; width: 100%; height: 100%;}


/* 매부리코 리뉴얼페이지 */
.sub-top-section.hooked-nose{background-image: url(../sub/img/hooked-nose-top-visual-pc.jpg); background-color: #dadee1;}

.hooked-nose-slide-box{padding:40px}

.round-tit-blue{ background-color: #007AFF; color: #fff; width: 226px; border-radius:2rem; -webkit-border-radius:2rem; -moz-border-radius:2rem; -ms-border-radius:2rem; -o-border-radius:2rem; padding:6px; font-weight: 500; text-align: center; margin-bottom: 10.8rem;}
.ml_30{margin-right: 30px;}

.hooked-nose-bna-item {
  width: 48%;
  max-width: 580px;
}
.hooked-nose-bna-item figure{width: 46.55%;}

.hooked-nose-steps .pinless-skull-list,.hooked-nose-steps .pinless-steps-list{width: 100%;}

.hooked-nose-steps .pinless-steps-list{max-width: 1180px;}
.hooked-nose-steps .pinless-slider-wrap{max-width: 1000px;}
.hooked-nose-steps .pinless-skull-list{height: 670px;}

.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num0::after,
.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num1::after,
.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num2::after{position: absolute; top:-125%; left:-50%; width: 200%; text-align: center;}

.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num0::after{content:'1단계';}
.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num1::after{content:'2단계';}
.hooked-nose-steps .pinless-slider-wrap .placeholder-circle.num2::after{content:'3단계';}

.hooked-nose-steps .pinless-steps-list li{display: flex; justify-content: space-between;}
.hooked-nose-steps .pinless-steps-list li > div{width: 210px;}
.hooked-nose-steps .pinless-steps-list li:nth-child(1).active .hooked-nose-step01 h5{color: #007AFF;}
.hooked-nose-steps .pinless-steps-list li:nth-child(2).active .hooked-nose-step02 h5{color: #007AFF;}
.hooked-nose-steps .pinless-steps-list li:nth-child(3).active .hooked-nose-step03 h5{color: #007AFF;}