@media only screen and (min-width: 1920px){
  .visual-bg{background-size: 1920px;}
}

@media only screen and (max-width: 1680px){
  .h1{font-size: 3rem;}
  .h2{font-size: 2.4rem;}
  .h3{font-size: 1.75rem;}
  .h4{font-size: 1.5rem;}
  .h5{font-size: 1.25rem;}
  .h6{font-size: 1rem;}

  .prs-contact-sect .column_half.text-area{padding-left: 5%;}
  .swiper-controls-wrap{bottom: 25%;}
  .main-visual-sect .container1140{max-width: 1070px;}
  .bna-model-bg{width: 1680px; height: 1038px;}
  .time-table.column_40{width: 85%; margin-bottom: 1.5rem;}
  .time-table.column_40.skin-time-table{margin-left:15%; margin-bottom: 0;}
  /* mediaelement */
  .video-wrapper{height: 810px;}
  
  /* sub9-2 */
  .thermage-solution-bg{height: 605px; background-size: cover;}
}

@media only screen and (max-width: 1440px){
  .h1{font-size: 2.75rem;}
  .h2{font-size: 2.25rem;}
  .h3{font-size: 1.5rem;}
  .h4{font-size: 1.25rem;}
  .h5{font-size: 1.125rem;}
  .hamberger{left:auto; right:20px;}
  .top-navigation{width: calc(100% - 200px);}
  .main-visual-sect .container1140{max-width: 930px;}
  .bna-model-bg{width: 1440px; height: 890px;}
  .visual-tit .container1140 {height: 240px;}
  .video-wrapper{height: 753px;}
  .sub-mid-section.natural-umbg{padding-top: 300px;}
  .case-item.umbg-case .div-line{height: 350px;}
  .prs-contact-sect .column_half.text-area{padding-left: 40px;}
}
@media only screen and (max-width: 1280px){
  .h1{font-size: 2.5rem;}
  .h2{font-size: 2rem;}
  .sub-top-section.full-top-section,
  .sub-btm-section.full-btm-section{background-size: cover;}
  .top-navigation{width: calc(100% - 180px); margin-right: 60px;}
  .main-visual-sect .container1140{max-width: 840px;}
  .bna-model-bg{width: 1280px; height: 791px;}
  /* .main-right-sect,
  .prs-contact-sect .column_half.text-area{padding-left: 80px;} */
  .yt-video-area{width: 66%;}
  .yt-thumb-area{width: 34%;}
  .yt-thumb-area .flex_row .text-wrap{font-size: 14px;}
  .video-wrapper{height: 673px;}
  .sub-mid-section.natural-umbg{padding-top: 240px;}
  /* sub9-2 */
  .thermage-solution-bg{height: 540px;}
}
@media only screen and (max-width: 1200px){
  .smooth-scroll{padding-top: 120px;}
  .container{max-width: 1100px; width: 94%;}
  .header-top-inner{height: 60px;}
  .top-navigation{display: none;}
  .main-visual-sect .container1140{max-width: 790px;}
  .bna-model-bg{width: 1200px; height: 741px;}
  .visual-tit .container1140 {height: 220px;}
  .main-right-sect,
  .prs-contact-sect .column_half.text-area{padding-left: 40px;}
  /* sub4-3 */
  .w1230 {
    width: 100%;
  }
  /* margin-bottom */
  .m_10{margin-bottom: 10px;}
  .m_20{margin-bottom: 15px;}
  .m_30{margin-bottom: 20px;}
  .m_40{margin-bottom: 28px;}
  .m_50{margin-bottom: 36px;}
  .m_60{margin-bottom: 45px;}
  .m_80{margin-bottom: 55px;}
  .m_100{margin-bottom: 66px;}
  .consult-sect .column_half{width: 100%;}
  .consult-sect .column_half.text-area{margin-bottom: 40px;}


  .video-wrapper{height: 596px;}

  /* sub9-2 */
  .thermage-solution-bg{height: 450px; background-position: 70% 50%;}

  /* sub9-4 */
  .syringoma-why-sect .column_33:nth-child(2n){display: none;}
  .syringoma-why-sect .column_33{width: 50%;}

  /* 리얼셀피 게시판 스타일 */
  /* .bbs-real-selfie-box{padding:40px 20px} */
  .bbs-real-selfie-box .column_25{width: 100%; margin-bottom: 2rem;}
  .bbs-real-selfie-box .column_75{padding:0; width: 100%;}
}
@media only screen and (max-width: 1024px){
  html,body{font-size: 15px;}
  .h1{font-size: 2.25rem;}
  .swiper-controls-wrap{bottom: 20%;}
  .main-visual-sect .container1140{max-width: 770px;}
  .visual-tit .container1140 {height: 160px;}
  .bna-model-bg{width: 1024px; height: 633px;}
  .yt-thumb-area{width: 34%; max-height: 460px;}
  /* sub4-3 */
  .round-circle-item {
    width: 23.5%;
    height: auto;
  }
  .sub-mid-section.natural-umbg{padding-top: 200px;}
  .case-item.umbg-case .div-line{height: 350px;}
  .secret-point-bg{background-position: 42% 50%;}
  .secret-point-bg .half-desc{width: 60%; margin-left: 40%;}
  .text-963px{width: 100%; padding: 0 40px;}
}
@media only screen and (max-width: 992px){
  
  .bna-model-bg{width: 992px; height: 613px;}
}
@media only screen and (max-width: 768px){

}


@media (max-width: 590px){ 
  /* Retina-specific stuff here */

}

@media (max-width: 480px){

}

@media (max-width: 375px){

}
