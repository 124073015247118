/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body{
	font:300 16px/1.3 'Brown', 'Noto Sans KR', sans-serif;
	color: #333;
	min-width: 992px;
	margin-left: auto;
	margin-right: auto;
}
body.open{width:100%; height:100%; overflow: hidden;}
body *{box-sizing: border-box; word-break: keep-all;}
a:visited, a:link{color: inherit; text-decoration: none;}
hr{border:0; margin:0; width:100%; height:1px; background: #000;}

/* font-family: 'Noto Sans KR', sans-serif; */
.skip-navigation {position: relative;}
.skip-navigation a {position: absolute; top: -200px; left: 0; border: 1px solid #fff; color: #fff; background: #333; line-height: 30px; width: 160px; text-align: center; text-decoration: none;}
.skip-navigation a:active, .skip-navigation a:focus {top: 0;}
img{max-width: 100%; vertical-align: top;}

/* font-size */
.h1{font-size: 3.125rem; font-weight: 300;}
.h2{font-size: 2.5rem; font-weight: 300;}
.h3{font-size: 1.875rem; font-weight: 300;}
.h4{font-size: 1.563rem; font-weight: 300;}
.h5{font-size: 1.25rem; font-weight: 300;}
.h6{font-size: 1rem; font-weight: 300;}

.p-text{opacity:0.8; color:#000; line-height: 1.5;}
.fz_14{font-size: 14px;}

/* text-color */
.c-white{color: #fff;}
.c-blue{color: #007aff;}
#content .show_detail.c-blue{color: #007aff;}
#content .show_detail:hover,#content .show_detail:focus	{text-decoration: underline;}

/* margin-bottom */
.m_10{margin-bottom: 10px;}
.m_20{margin-bottom: 20px;}
.m_30{margin-bottom: 30px;}
.m_40{margin-bottom: 40px;}
.m_50{margin-bottom: 50px;}
.m_60{margin-bottom: 60px;}
.m_80{margin-bottom: 80px;}
.m_100{margin-bottom: 100px;}

/* text */
.ta_c{text-align: center;}
.ta_l{text-align: left;}
.ta_r{text-align: right;}

.light{font-weight: 200;}
.normal{font-weight: 300;}
.medium{font-weight: 400;}
.bold{font-weight: 600;}

.lh_15{line-height: 1.5;}
.lh_16{line-height: 1.6;}

/* flex-box */

.flex_row{display: flex; }
.flex_row.fw{flex-wrap:wrap; }
.flex_row.center{display: flex; align-items: center;}
.flex_row.jc_center{justify-content: center;}
.flex_row.sb{justify-content:space-between;}
.flex_row.sa{justify-content:space-around;}

/* for ie */
.ie-block{display:none}
.Explorer .ie-block{display:block}
.Explorer .webkit-block{display:none}


/* 탑 배너 */
.top-bnr-area{display: block; width: 100%; height: 60px; background:#000 url(../img/top-bnr-bg.png); position: relative; overflow: hidden; z-index: 0;}
.top-bnr-area canvas{opacity: .7;}
.text-gradient-img{
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	z-index: 10;
}
.text-gradient-bg{
	position: absolute;
	top:50%;
	left:50%;
	animation: gradient360 10s linear infinite;
	background-image: linear-gradient(to right, #e6e9f4, #e1f7f2, #e9e7ea, #e9ddf0, #f2d9f4, #f5dcf0, #f8e7dc, #f7eadc, #e9f4e8, #e1f5ee, #eef6e0, #def7f4, #dedcf6, #e2d8f8);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	text-transform: uppercase;
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	transform: translate(-50%,-50%);
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	z-index: 10;
	-webkit-animation: gradient360 6s linear infinite;
}
.right-arr-gradient{
	width: 24px;
	height: 17px;
	display: inline-block;
	background: url(../img/right-arr-gradient.png)0 0/100% no-repeat;
}

@keyframes gradient360 {
  0% {
    -webkit-filter: hue-rotate(-360deg);
            filter: hue-rotate(-360deg);
  }
  100% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
}


/* 레이아웃 공통 */
.main-header{ position: fixed; width: 100%; z-index: 104; top:0; left:0; background: #fff;}
.main-header.plus-top{position: fixed; top:-60px;}
.container{max-width: 1540px; width: 98%; position: relative;  margin-left:auto; margin-right:auto; padding-left:20px; padding-right:20px; clear: both;}
.container1220{max-width: 1280px; margin-left:auto; margin-right: auto; padding:0 30px; position: relative;}
.container1140{max-width: 1140px; margin-left:auto; margin-right: auto; padding:0 30px; position: relative;}


.header-top-container{max-width: 1760px; width: 98%; position: relative;  margin-left:auto; margin-right:auto; padding-left:20px; padding-right:20px; clear: both;}

.logo {
	width:80px;
	height: 24px;
	background: url(../img/noteprs_logo2x.png)0 0/100% no-repeat;
}
.logo a{
	width: 100%;
	height: 100%;
	display: block;
	text-indent: -9999em;
}
.header-top-inner{display: flex; justify-content:space-between; align-items: center; height: 80px;}
.main-header.plus-top .header-top-inner{height: 60px;}
.top-navigation{width:67.44%;}
#header .main-menu.depth1{display: flex; justify-content:space-around; align-items: baseline;}
#header .main-menu.depth1 li{position: relative;}
#header .main-menu.depth1 li:nth-child(1){font-weight:300;}
#header .main-menu.depth1 li:nth-child(11){font-weight:300;}
#header .main-menu.depth1 li:nth-child(12){font-weight:300;}
#header .main-menu.depth1 li:nth-child(13){font-weight:300;}
#header .main-menu.depth1 > li > a{padding: 1.125rem .6rem; display: block; }
#header .main-menu.depth1 > li > a > span{line-height: 1.5; display: block; }
#header .main-menu.depth2{position: absolute; top:99%; left:-1em; width:328px;z-index: 10; background: #fff; border:1px solid #333; border-top:0; opacity: 0; visibility: hidden;}

#header .main-menu.depth2 > li > a{
	display: block;
	font-size: 1rem;
	padding: 8px 15px;
	line-height: 1.438;
}

.hamberger {
	/* position: fixed;
	top: 0;
	right: 3%; */
	width: 3.6rem;
	height: 3.6rem;
	z-index: 104;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.hamburger{width: 35px;}
.hamburger-box strong{display: none;}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 35px;
	height: 1px;
	background-color: #000;
	border-radius: 3px;
	position: absolute;
	transition: transform 0.15s ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -10px;
}

.hamburger-inner::after {
	bottom: -10px;
}

/*
* Spin
*/
.hamburger--spin .hamburger-inner {
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
	transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.15s ease;
}

.hamburger--spin .hamburger-inner::after {
	transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.15s ease;
}

.open .hamburger--spin .hamburger-inner {
	transform: rotate(225deg);
	transition-delay: 0.14s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.open .hamburger--spin .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.15s ease;
}

.open .hamburger--spin .hamburger-inner::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.15s ease;
}


.gnb {
	position: fixed;
	top: 60px;
	right: -100%;
	width: 100%;
	max-width: 400px;
	height: 100%;
	padding: 80px 0 0;
	z-index: 103;
	background-color: #fff;
	transition: transform .4s ease-out;
	-webkit-transition: transform .4s ease-out;
	-moz-transition: transform .4s ease-out;
	-ms-transition: transform .4s ease-out;
	-o-transition: transform .4s ease-out;
}

.gnb.open {
	transform: translate3d(-100vw, 0, 0);
	-webkit-transform: translate3d(-100vw, 0, 0);
	-moz-transform: translate3d(-100vw, 0, 0);
	-ms-transform: translate3d(-100vw, 0, 0);
	-o-transform: translate3d(-100vw, 0, 0);
}
.gnb.plus-top{top:0; padding: 60px 0 0;}
.gnb-wrap {
	height: 100%;
	padding: 0 0 100px;
	overflow-y: auto;
}

#globalMenu li a{display: block; padding: 14px 13px 13px 50px; line-height: 1.75;}
#globalMenu .global-menu-depth1 > li { height: 56px; overflow: hidden; font-weight:400;}
#globalMenu .global-menu-depth1 > li:nth-child(1){font-weight:300;}
#globalMenu .global-menu-depth1 > li:nth-child(11){font-weight:300;}
#globalMenu .global-menu-depth1 > li:nth-child(12){font-weight:300;}
#globalMenu .global-menu-depth1 > li:nth-child(13){font-weight:300;}
#globalMenu .global-menu-depth1 > li > a{height: 56px; border-bottom:1px solid #333;}
#globalMenu .global-menu-depth2 > li {border-bottom:1px solid #bbb;}
#globalMenu .global-menu-depth2 > li > a::before{content:'- ';}

.gnb-wrap::-webkit-scrollbar {width: 5px;}

.gnb-wrap::-webkit-scrollbar-thumb {
	background-color: #333;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.gnb-wrap::-webkit-scrollbar-track {background-color: #bbb;}

.gnb-bg {
	position: fixed;
	top: 0;
	right: -100%;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .5);
	opacity: 0;
	z-index: 9;
	transition: opacity .4s;
	-webkit-transition: opacity .4s;
	-moz-transition: opacity .4s;
	-ms-transition: opacity .4s;
	-o-transition: opacity .4s;
}

.gnb-bg.open {
	opacity: 1;
	right: 0;
}

.smooth-scroll{padding-top: 140px;}


/* CONTACT */
.prs-contact-sect .column_half.text-area{padding-left: 11%; padding-right: 0; position: relative; display: flex; flex-flow: column; justify-content: space-between;}
.upper{text-transform: uppercase;}
.prs-time-tit,.skin-time-tit{display: inline-block;}
.prs-time-tit{width: 4.5em;}
.skin-time-tit{width: 6.5em;}
.parking-info{width: 90%; background-color: #f9f9f9; padding: .5rem .5rem .5rem 1.25rem;}

.time-table li{padding:3px 0;}
.table-info{background-color: transparent;}

/* 푸터 */
#footer{border-top:1px solid #000; height: 455px; padding:75px 0 200px;}
.footer-inner{
	width: 100%;
	max-width: 1580px;
	padding:0 40px;
	margin-left: auto;
	margin-right: auto;
}

.social-icons{display: flex; width: 288px;}
.sns-link{width: 2rem; height: 1.125rem; background: url(../img/note-social-sprite.png)0 0/400% no-repeat; margin-right: 1rem;}
.sns-link a{text-indent: -9999em; display: block; width: 100%; height: 100%;}
.sns-link.insta{background-position: 0 0;}
.sns-link.facebook{background-position: 33.3333% 0;}
.sns-link.youtube{background-position: 66.6666% 0;}
.sns-link.blog{background-position: 100% 0; margin-right: 0; margin-left: .6rem;}

.footer-logo{width: 170px;}
.footer-info-area{margin-left: 170px;}
.footer-info-list{display: flex; flex-wrap: wrap;}
.footer-info-list li{margin-right: 1rem; padding-left:1rem; border-left: 1px solid #e6e6e6; font-size: 14px;}
.footer-info-list li:first-child{padding-left:0; border-left: 0}
.footer-info-list address{display: inline;}


.select-lang-wrap{width: 150px; position: relative;}
.select-lang-btn{width: 100%; display: inline-block; border:1px solid #111; text-align: left; padding:5px 10px; margin:0;font-family: inherit; font-size: 14px; background:#fff url(../img/triangle-icon.png)90% 50%/6px no-repeat; cursor: pointer; font-weight: 300;}
.lang-flag{display: inline-block; width: 1.25rem; height: 1rem; background-image: url(../img/lang-flag-icon.png); background-repeat: no-repeat; background-size: 400%; vertical-align: middle; margin-right: 4px;}
.lang-flag.ko{background-position: 0 0;}
.lang-flag.en{background-position: 33.3333% 0;}
.lang-flag.th{background-position: 66.6666% 0;}
.lang-flag.cn{background-position: 100% 0;}

.lang-select-list {
	position: absolute;
	left: 0;
	bottom: 32px;
	width: 100%;
	height: 0;
	opacity: 0;
	pointer-events: none;
	padding: 10px 0;
	box-shadow: 10px 10px 20px 0 rgba(55, 55, 55, .12);
	border: 1px solid #888;
	background-color: #fff;
	transition:all .6s ease;
	-webkit-transition:all .6s ease;
	-moz-transition:all .6s ease;
	-ms-transition:all .6s ease;
	-o-transition:all .6s ease;
	overflow: hidden;
}
.select-lang-wrap.open .lang-select-list{height: 148px; opacity: 1; pointer-events: fill;}

.lang-select-list li{width: 100%;  padding:5px 10px; font-family: inherit; font-weight: normal;}
.lang-select-list li a{font-weight: 300;}
.lang-select-list li:hover{background-color: #333;}
.lang-select-list li:hover a{color: #fff;}


#topBtn{position: fixed; bottom:100px; right:100px;}
#topBtn > a{ width: 3.125rem; height: 3.125rem; display: block; text-indent: -9999em; background:#fff url(../img/top-btn-img.png)50% 50% no-repeat; border:1px solid #828282; border-radius:2px; -webkit-border-radius:2px; -moz-border-radius:2px; -ms-border-radius:2px; -o-border-radius:2px; }



/* 퀵메뉴 */

.quick_note_counsel{ position: fixed; width: 14rem; right:70px; bottom:300px; background-color: #fff; padding:1.4rem 0 0; opacity: 0; visibility: hidden; border-radius: 1.4rem; -webkit-border-radius: 1.4rem; -moz-border-radius: 1.4rem; -ms-border-radius: 1.4rem; -o-border-radius: 1.4rem; z-index:2;transition:right .3s; -webkit-transition:right .3s; -moz-transition:right .3s; -ms-transition:right .3s; -o-transition:right .3s;}
.quick_note_counsel.plus-bottom{right: 220px;}
.db_collect_name input[type=text]{
	-webkit-appearance: none;
	border: 1px solid #111;
	padding: 8px 6px;
	outline: none;
	color: #777;
	margin: 0;
	width: 100%;
	display: block;
	background: #fff;
	border-radius: 0px;
}

.db_collect_callnum::after{content:''; display: block; clear: both;}
.db_collect_callnum select{width: 60px; height: 32px; float:left; border: 1px solid #111; margin-right: 5px;}
.db_collect_subjects select{border: 1px solid #111; width: 128px; height: 32px; margin-left: 10px;}
.db_collect_callnum option{
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
.db_collect_callnum input[type=text]{
	-webkit-appearance: none;
	border: 1px solid #111;
	padding: 8px 6px;
	outline: none;
	color: #777;
	margin: 0 5px 0 0;
	width: 60px;
	display: block;
	background: #fff;
	border-radius: 0px;
	float:left;
}
.db_collect_callnum input[type=text]:last-child{margin:0;}
.db_collect_desc_text{font-size: 13px; padding:0 1em; word-break: keep-all; margin-bottom: 8px;}
.db_collect_agree_y{font-size: 12px;}
.db_collect_agree_y .db_collect_agree_block > *{vertical-align: middle;}
.db_collect_agree_y textarea{width: 100%; height: 3rem; font-size: 12px; resize: none;}
.db_collect_submit input[type=submit]{ width: 100%; background-color: #131313; color: #fff; border:0; margin:0; padding:0; border-radius:1rem ; -webkit-border-radius:1rem ; -moz-border-radius:1rem ; -ms-border-radius:1rem ; -o-border-radius:1rem ; appearance: none; padding:8px 6px; }

.db_collect_agree_block{margin-bottom: 10px;}
.db_collect_subjects > *{font-size: 13px;}
.db_collect_name,.db_collect_callnum,.db_collect_subjects,.db_collect_agree_y{padding:6px 10px; font-size: 13px;}
.db_collect_submit{padding:0 8px 8px;}
.quick-form-close{position: absolute; top:8px; right:8px; z-index: 1;}
.quick-form-close .close-btn{opacity: 1; background-color: #131313; border:0;}
.quick-form-close .close-btn span{background-color: #fff;}

.quick-wrap{ position: fixed; width: 9rem; height: 220px; right:70px; bottom:50px; z-index: 100; transition:bottom .3s; -webkit-transition:bottom .3s; -moz-transition:bottom .3s; -ms-transition:bottom .3s; -o-transition:bottom .3s; }
.quick-wrap.plus-bottom{bottom: 190px; }
.quick-menu{ width: 9rem; position: absolute; bottom:60px; right:0;}
.quick-menu li {
	visibility: hidden;
	font-size: 14px;
	margin-bottom: 10px;
	line-height: 2.125rem;
	height: 2.125rem;
	text-align: center;
	border-radius: 4rem;
	-webkit-border-radius: 4rem;
	-moz-border-radius: 4rem;
	-ms-border-radius: 4rem;
	-o-border-radius: 4rem;
	opacity:0;
	transition:opacity .8s ease;
	-webkit-transition:opacity .8s ease;
	-moz-transition:opacity .8s ease;
	-ms-transition:opacity .8s ease;
	-o-transition:opacity .8s ease;
	/*transform: translateY(80%);
	-webkit-transform: translateY(80%);
	-moz-transform: translateY(80%);
	-ms-transform: translateY(80%);
	-o-transform: translateY(80%);
	*/
}

#quick.open .quick-menu li {
	visibility: visible;
	opacity: 1;
	/* transform: translateY(0%);
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-ms-transform: translateY(0%);
	-o-transform: translateY(0%); */
}
/* #quick.open .quick-menu li:nth-child(1){ transition-delay:.2s; }
#quick.open .quick-menu li:nth-child(2){ transition-delay:.15s; }
#quick.open .quick-menu li:nth-child(3){ transition-delay:.1s; }
#quick.open .quick-menu li:nth-child(4){ transition-delay:.05s; } */

.quick-menu li a {
	display: flex;
	height: 100%;
}

.quick-menu li a > span{
	display: block;
	width: 6.25rem;
	height: 100%;
	background-color: #111;
	color: #fff;
	border-radius: 4rem;
	-webkit-border-radius: 4rem;
	-moz-border-radius: 4rem;
	-ms-border-radius: 4rem;
	-o-border-radius: 4rem;
	margin-right:0.625rem;
}

.quick-icon{
	display: block;
	width: 2.125rem;
	height: 2.125rem;
	border-radius: 4rem;
	-webkit-border-radius: 4rem;
	-moz-border-radius: 4rem;
	-ms-border-radius: 4rem;
	-o-border-radius: 4rem;
	background-color: #111;
	/* background-image: url(../img/quick-icon-sprite.png);
	background-size: 400%; */
	background-position: 50% 50%;
	background-size: 60%;
	background-repeat: no-repeat;
}

.quick-icon.q-online{background-image: url(../img/question.svg);}
.quick-icon.q-kakao{background-image: url(../img/kakao-talk.svg);}
.quick-icon.q-counsel{background-image: url(../img/calculator.svg);}
.quick-icon.q-event{background-image: url(../img/event.svg);}

.quick-call-btn {
	position: absolute;
	bottom: 0.8rem;
	right: -0.8rem;
	width: 3.75rem;
	height: 3.75rem;
	background: transparent;
	border:0;
	outline:none;
	padding:0;
}

.quick-call-btn span{
	display: block;
}



.quick-call-btn .here-btn{
	display: block;
	width: 3.75rem;
	height: 3.75rem;
	background-color: #000;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border: 0;
	padding: 1.2rem 0 0;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	transition:.3s ease;
	-webkit-transition:.3s ease;
	-moz-transition:.3s ease;
	-ms-transition:.3s ease;
	-o-transition:.3s ease;
	box-shadow: 3px 2px 10px 1px rgba(0, 0, 0, 0.24);
	overflow: hidden;
}

.quick-call-btn:active .here-btn{
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	transform: scale(0.92);
	-webkit-transform: scale(0.92);
	-moz-transform: scale(0.92);
	-ms-transform: scale(0.92);
	-o-transform: scale(0.92);
}
/* .here-btn{
	animation: blinkEye 1s infinite .4s;
	-webkit-animation: blinkEye 1s infinite .4s;
} */
@keyframes blinkEye{
	0%{
		transform:scaleY(.15);
		-webkit-transform:scaleY(.15);
		-moz-transform:scaleY(.15);
		-ms-transform:scaleY(.15);
		-o-transform:scaleY(.15);
}
	20%{
		transform:scaleY(1);
		-webkit-transform:scaleY(1);
		-moz-transform:scaleY(1);
		-ms-transform:scaleY(1);
		-o-transform:scaleY(1);
	}
	40%{
		transform:scaleY(.15);
		-webkit-transform:scaleY(.15);
		-moz-transform:scaleY(.15);
		-ms-transform:scaleY(.15);
		-o-transform:scaleY(.15);
}
	60%{
		transform:scaleY(1);
		-webkit-transform:scaleY(1);
		-moz-transform:scaleY(1);
		-ms-transform:scaleY(1);
		-o-transform:scaleY(1);
}
	75%{
		transform:scaleY(.15);
		-webkit-transform:scaleY(.15);
		-moz-transform:scaleY(.15);
		-ms-transform:scaleY(.15);
		-o-transform:scaleY(.15);
}
	100%{
		transform:scaleY(.15);
		-webkit-transform:scaleY(.15);
		-moz-transform:scaleY(.15);
		-ms-transform:scaleY(.15);
		-o-transform:scaleY(.15);
}
}

.close-btn {
	position: absolute;
	top: calc(50% - 1.0625rem);
	right: calc(50% - 1.0625rem);
	width: 2.125rem;
	height: 2.125rem;
	opacity: 0;
	background: #fff;
	cursor: pointer;
	border-radius: 2.125rem;
	-webkit-border-radius: 2.125rem;
	-moz-border-radius: 2.125rem;
	-ms-border-radius: 2.125rem;
	-o-border-radius: 2.125rem;
	border: 1px solid #131313;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
	-o-transition: .5s ease;
}
.close-btn span {
	width: 10px;
	height: 1px;
	background: #131313;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	-webkit-transform: translate(-50%, -50%) rotate(45deg);
	-moz-transform: translate(-50%, -50%) rotate(45deg);
	-ms-transform: translate(-50%, -50%) rotate(45deg);
	-o-transform: translate(-50%, -50%) rotate(45deg);
}
.close-btn span:last-child{
	transform: translate(-50%, -50%) rotate(-45deg);
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
}
#quick.open .here-btn{opacity: 0;}
#quick.open .blink-ani{opacity: 0;}
#quick.open .close-btn{opacity: 1;}

/* .blink-ani > span{
	display: block;
	height: 50%;
	transform-origin: top;
	animation: blink 2s ease infinite;
	-webkit-animation: blink 2s ease infinite;
	background-color: #000;
	z-index: 2;
}
.blink-ani > span:last-child{
	transform-origin: bottom;
} */

/* @keyframes blink {
	0% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
		-moz-transform: scaleY(1);
		-ms-transform: scaleY(1);
		-o-transform: scaleY(1);
	}

	25% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
		-moz-transform: scaleY(0.4);
		-ms-transform: scaleY(0.4);
		-o-transform: scaleY(0.4);
	}

	40% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
		-moz-transform: scaleY(1);
		-ms-transform: scaleY(1);
		-o-transform: scaleY(1);
	}

	60% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
		-moz-transform: scaleY(0.4);
		-ms-transform: scaleY(0.4);
		-o-transform: scaleY(0.4);
}

	100% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
		-moz-transform: scaleY(1);
		-ms-transform: scaleY(1);
		-o-transform: scaleY(1);
}
} */

/* 게시판페이지 상단 동영상 */
.board-video-bnr {position: relative; width: 100%; height: 400px; background: #000; overflow: hidden; margin-bottom: 4rem;}
.board-video-bnr video {position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); pointer-events: none; opacity: 0.8;}

.bo_subject_tit{
	position: absolute;
	top: 40%;
	left: 0;
	width: 100%;
	z-index: 1;
	color: #fff;
}
.quick-call-btn:focus,.select-lang-btn:focus{outline:none;}